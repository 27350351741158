import { VacationLeaveApi } from '@/api/attendance/leave/VacationLeaveApi';

export class VacationLeaveService {
    private api = new VacationLeaveApi();

    public submit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.submit(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public beforeSubmit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.beforeSubmit(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public delete(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.delete(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getNextStepUsers(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getNextStepUsers(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getSelectLeaveApplyList(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getSelectLeaveApplyList().then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //请假历史 
    public getMyhistory(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getMyhistory(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    //上传附件
    public uploadFile(): string {
        return this.api.uploadFile();
    }
}