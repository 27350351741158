import AttendanceBaseService from '../base/AttendanceBaseService';

export class VacationLeaveApi extends AttendanceBaseService {
    constructor() {
        super("vacation/leave");
    }

    /**
     * 上传附件
    */ 
    public uploadFile(): string {
        const path = 'uploadAtt';
        return this.getUrl(path);
    }

    /**
     * 提交
    */
    public submit(params: any): Promise<any> {
        return this.request('save', this.rest.METHOD_POST, params);
    }
    /**
     * 提交前的验证
    */
    public beforeSubmit(params: any): Promise<any> {
        return this.request('beforeSave', this.rest.METHOD_POST, params);
    }
    /**
     * 获取详细
    */
    public getInfo(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
    /**
     * 删除
    */
    public delete(params: any): Promise<any> {
        return this.request('delete?id=' + params.id, this.rest.METHOD_POST, params);
    }
    /**
     * 获取下一步骤用户
    */
    public getNextStepUsers(params: any): Promise<any> {
        return this.request('nextStepUsers', this.rest.METHOD_GET, params);
    }
    /**
     *获取列表
    */
    public getList(params: any): Promise<any> {
        return this.request('list', this.rest.METHOD_GET, params);
    }
    /**
    *获取选择请假申请
   */
    public getSelectLeaveApplyList(): Promise<any> {
        return this.request('unCancel/list', this.rest.METHOD_GET, {});
    }
    /**
* 获取我的请假历史
*/
    public getMyhistory(params: any): Promise<any> {
        return this.request('myhistory', this.rest.METHOD_GET, params);
    }

}