
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import SelectExecutor from "@/components/selectexecutor/SelectExecutor.vue";
import Utils from "@/utils/Utils";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import CardLoading from "@/components/ListCard/CardLoading.vue";
export default defineComponent({
  name: "leaveList",
  components: {
    HeaderModal,
    CardLoading,
  },
  props: {
    leaveObj: Object,
  },
  data() {
    const leaveService = new VacationLeaveService();
    return {
      btnIconRight: ["iconduihao"],
      btnSizeRight: ["50px"],
      list: new Array(),
      SelectLeaveObj: this.leaveObj,
      leaveService,
      id: "",
      isList: true,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    checkHandler(e: any, item: any) {
      if (e) {
        this.SelectLeaveObj = item;
      }
    },
    clickHandler(item: any) {
      if (this.SelectLeaveObj == null || this.SelectLeaveObj == {}) {
        this.SelectLeaveObj = item;
      } else if (this.SelectLeaveObj.id == item.id) {
        this.SelectLeaveObj = {};
      } else {
        this.SelectLeaveObj = item;
      }
    },
    closeModel(type: number) {
      if (type == 4) {
        if ("id" in Object(this.SelectLeaveObj)) {
          modalController.dismiss({ leaveObj: this.SelectLeaveObj });
        } else {
          Utils.presentToastWarning("请选择一条请假信息");
        }
      } else {
        modalController.dismiss();
      }
    },
    //获取list
    getList() {
      let that = this;
      this.leaveService
        .getSelectLeaveApplyList()
        .then((result: any) => {
          if (result) {
            this.isList = false;
            that.list = result;
            if (result.length == 1) {
              that.id = result[0].id;
            }
          }
        })
        .catch(() => {});
    },
    doRefresh(event: any) {
      this.getList();
      setTimeout(() => {
        event.target.complete();
      }, 600);
    },
  },
});
