import AttendanceBaseService from '../base/AttendanceBaseService';

export class CancleLeaveApi extends AttendanceBaseService {
    constructor() {
        super("vacation/cancelLeave");
    }
    /**
     * 上传附件
    */
    //  public uploadFile(params: any, success: any) {
    //     this.rest.uploadFile('attendance/vacation/cancelLeave/uploadAtt', params, success, '上传中');
    // }

    /**
     * 提交
    */
    public submit(params: any): Promise<any> {
        return this.request('save', this.rest.METHOD_POST, params);
    }
    /**
     * 提交前的验证
    */
    public beforeSubmit(params: any): Promise<any> {
        return this.request('beforeSave', this.rest.METHOD_POST, params);
    }
    /**
     * 获取详细
    */
    public getInfo(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
    /**
     * 获取选择请假申请
    */
    public delete(params: any): Promise<any> {
        return this.request('delete?id=' + params.id, this.rest.METHOD_POST, {});
    }
    /**
     * 获取列表
    */
    public getList(params: any): Promise<any> {
        return this.request('list', this.rest.METHOD_GET, params);
    }
}