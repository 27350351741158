import AttendanceBaseService from '../base/AttendanceBaseService';

export class LeaveTypeApi extends AttendanceBaseService {
    constructor() {
        super("vacation/type");
    }

    /**
   * 获取假期类型
   */
    public getVacationType(): Promise<any> {
        return this.request('enabledlist', this.rest.METHOD_GET, {});
    }
    /**
   * 获取假期详情
   */
    public getVacationDetail(params: any): Promise<any> {
        return this.request('detail', this.rest.METHOD_GET, params);
    }
}