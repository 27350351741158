import { LeaveTypeApi } from '@/api/attendance/leavetype/LeaveTypeApi';

export class LeaveTypeService {
    private api = new LeaveTypeApi();

    public getVacationType(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getVacationType().then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getVacationDetail(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getVacationDetail(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}