
import { defineComponent, reactive, onMounted, ref, toRefs } from "vue";
import { modalController } from "@ionic/vue";
import { useRouter } from "vue-router";
import HeaderModal from "@/components/modal/components/HeaderModal.vue";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService.ts";
import Utils from "@/utils/Utils";
import SystemUtils from "@/utils/SystemUtils";
import moment from "moment";
import AvatarList from "@/components/AvatarList.vue";

export default defineComponent({
  name: "SendPage",
  components: {
    HeaderModal,
    AvatarList,
  },
  props: {
    data: Object,
  },
  setup(props, { emit }) {
    const { data } = toRefs(props);
    const businessId = ref("");
    const taskId = ref("");
    const procId = ref("");
    const receiveParameters = ref("");
    const userList = reactive({ data: new Array<any>() });

    const workflowService = new WorkflowService();
    const currentUser: any = SystemUtils.loginUser;
    const userId = ref("");
    const selectedId = ref("");
    const selectedItem = reactive({ name: "", orgName: "", id: "" });

    const initData = () => {
      if (data && data.value && data.value.businessId) {
        businessId.value = data.value.businessId;
      }
      if (data && data.value && data.value.taskId) {
        taskId.value = data.value.taskId;
      }
      if (data && data.value && data.value.procId) {
        procId.value = data.value.procId;
      }
      if (data && data.value && data.value.receiveParameters) {
        receiveParameters.value = data.value.receiveParameters;
      }
      if (data && data.value && data.value.userList) {
        userList.data = data.value.userList;
      }

      if (userList.data.length > 0) {
        let item: any = userList.data[0];
        userId.value = item.id;
      }
    };
    const clickItemHandler = (item: any) => {
      if (selectedId.value == item.id) {
        selectedId.value = "";
      } else {
        selectedId.value = item.id;
      }
    };
    const checkHandler = (e: any, item: any) => {
      if (e.detail.checked) {
        selectedItem.name = item.name;
        selectedItem.id = item.id;
        selectedItem.orgName = item.orgName;
        selectedId.value = item.id;
      }
    };
    const avatarData = (item: any) => {
      var users: any = [];
      let obj = { name: item.name, img: "", id: "" };
      users.push(obj);
      return users;
    };
    const isClick = ref(true);
    const closeModel = (type: any) => {
      if (type == 4) {
        if (isClick.value) {
          Utils.confirmDialog1("确认", "请确认是否提交该申请", function () {
            let userIds = new Array();
            if (
              !(
                userId.value == undefined ||
                userId.value == null ||
                userId.value == ""
              )
            ) {
              userIds.push(userId.value);
            }
            let paramSend = {
              procId: procId.value,
              taskId: taskId.value,
              listReciveVar: [
                {
                  key: receiveParameters.value,
                  value: userIds,
                },
              ],
              option: "",
            };
            workflowService
              .send(paramSend)
              .then((resultSend: any) => {
                if (resultSend.result) {
                  Utils.presentToastSuccess("提交成功");
                  modalController.dismiss({ save: true });
                } else {
                  Utils.presentToastWarning("提交失败");
                }
                isClick.value = true;
              })
              .catch(() => {
                isClick.value = true;
              });
          },function(){
             isClick.value = true;
          });
        }
      } else {
        modalController.dismiss({});
      }
    };
    onMounted(() => {
      initData();
    });
    return {
      isRollback: true,
      closeModel,
      clickItemHandler,
      avatarData,
      userList,
      selectedId,
      checkHandler,
    };
  },
});
