
import { defineComponent } from "vue";
import { modalController } from "@ionic/vue";
import Header from "@/components/Header.vue";
import LeaveList from "@/components/attendance/leaveList.vue";
import { CancleLeaveService } from "@/services/attendance/cancelleave/CancelLeaveService";
import { VacationLeaveService } from "@/services/attendance/leave/VacationLeaveService";
import { LeaveTypeService } from "@/services/attendance/leavetype/LeaveTypeService";
import { WorkflowService } from "@/services/attendance/workflow/WorkflowService";
import Utils from "@/utils/Utils";
import SendPage from "@/views/attendance/apply/SendPage.vue";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
export default defineComponent({
  name: "cancelLeave",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    const cancleLeaveService = new CancleLeaveService();
    const leaveService = new VacationLeaveService();
    const leaveTypeService = new LeaveTypeService();
    const workflowService = new WorkflowService();
    return {
      router,
      query: useRoute().query,
      cancleLeaveService,
      leaveService,
      leaveTypeService,
      workflowService,
      btnIconRight: ["iconduihao"],
      btnIconRightHasDelete: ["iconduihao", "iconhuishouzhan"],
      btnSizeRight: ["50px"],
      btnSizeRightHasDelete: ["50px", "50px"],
      leaveObj: {
        id: "",
        vacationType: "",
        vacationTypeId: 0,
        extType: "",
        startDate: "",
        startNoon: "",
        endDate: "",
        endNoon: "",
        days: 0,
        reason: "",
        attachmentRequired: -1,
        vacationCode: "",
        attachments: [],
      },
      cancelType: 0,
      startDate: "",
      startNoon: "AM",
      endDate: "",
      endNoon: "AM",
      reason: "",
      id: "",
      vacationTypeId: "",
      vacationType: "",
      extType: "",
      attachments: new Array(),
      isClick: true,
      days: null,
      businessId: "",
      taskId: "",
      procId: "",
      cjCode: "LeaveType_Maternity", //产假
      firstLeaders: new Array<any>(),
      userId: "",
      showDelete: false,
      handleType: 99999, //0:待我审批  1：我的已办  99999：新增
      procStatus: -1,
    };
  },
  mounted() {
    this.showDelete = false;
    if (this.query) {
      if (this.query.handleType) {
        this.handleType = parseInt(this.query.handleType as string);
      }
      if (this.query.id) {
        this.id = this.query.id as string;
        this.procId = this.query.procId as string;
        this.taskId = this.query.taskId as string;
        this.procStatus = Number(this.query.procStatus);
        if (this.id != "" && this.procStatus == 0) {
          this.showDelete = true;
        }
        this.getInfo();
      }
    }
  },
  methods: {
    //开始日期
    selectstartNoon() {
      let selectOperate: any = document.getElementById("start_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindstartNoon(ev: any) {
      this.startNoon = ev.detail.value;
    },
    //结束日期
    selectendNoon() {
      let selectOperate: any = document.getElementById("end_noon");
      let el: HTMLElement = selectOperate;
      el.click();
    },
    bindendNoon(ev: any) {
      this.endNoon = ev.detail.value;
    },
    //销假类型
    cancelTypeChanged(type: any) {
      this.cancelType = type;
    },
    //选择请假申请
    async selectLeave() {
      const modal = await modalController.create({
        component: LeaveList,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          leaveObj: this.leaveObj,
          leaveId: this.leaveObj.id,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data && data.leaveObj) {
        this.leaveObj = data.leaveObj;
        this.vacationTypeId = data.leaveObj.vacationTypeId;
        this.vacationType = data.leaveObj.vacationType;

        this.getLeaveApplyInfo(this.leaveObj.id);
      }
    },
    getInfo(id?: string) {
      let that = this;
      let params = {};
      if (id) params = { id: this.id };
      else params = { id: id };
      this.cancleLeaveService
        .getInfo(params)
        .then((result: any) => {
          if (result) {
            this.vacationTypeId = result.vacationTypeId;
            this.vacationType = result.vacationType;
            this.extType = result.extType;
            this.startDate = result.startDate;
            this.startNoon = result.startNoon;
            //  this.startNoonText = result.startNoon == 'AM' ? '上午' : '下午';
            this.endDate = result.endDate;
            this.endNoon = result.endNoon;
            //this.endNoonText = result.endNoon == 'AM' ? '上午' : '下午';
            this.reason = result.reason;
            this.cancelType = result.cancelLeave ? 1 : 0;
            this.attachments = [];
            for (let i = 0; i < result.attachments.length; i++) {
              let file = {
                base64Image: null,
                httpPath: result.attachments[i].path,
                relativePath: null,
                path: result.attachments[i].path,
                size: null,
                type: 0,
                ext: "",
              };
              this.attachments.push(file);
            }
            this.getLeaveApplyInfo(result.leaveId);
          }
        })
        .catch(() => {});
    },
    getLeaveApplyInfo(id: string) {
      let that = this;
      let params = { id: id };
      this.leaveService
        .getInfo(params)
        .then((result: any) => {
          if (result) {
            this.leaveObj = result;
            this.vacationTypeId = result.vacationTypeId;
            this.vacationType = result.vacationType;
            this.extType = result.extType;
            this.startDate = result.startDate;
            this.startNoon = result.startNoon;
            this.endDate = result.endDate;
            this.endNoon = result.endNoon;
            this.attachments = result.attachments;
            for (let pic of this.attachments) {
              pic = Object.assign(pic, { isImage: true, url: pic["path"] });
            }
            this.getVacationDetail(this.vacationTypeId);
          }
        })
        .catch(() => {});
    },
    getVacationDetail(id: string) {
      let that = this;
      let params = { id: id };
      this.leaveTypeService
        .getVacationDetail(params)
        .then((result: any) => {
          if (result) {
            this.leaveObj.attachmentRequired = result.attachmentRequired;
            this.leaveObj.vacationCode = result.code;
          }
        })
        .catch(() => {});
    },
    finishHandler(item: any) {
      if (item == "0_icon") {
        this.saveHandler();
      } else {
        this.deleteHandle();
      }
    },
    //保存
    saveHandler() {
      if (this.isClick) {
        this.isClick = false;
        let {
          id,
          vacationType,
          vacationTypeId,
          extType,
          startDate,
          startNoon,
          endDate,
          endNoon,
          days,
          reason,
          attachments,
          procId,
          taskId,
        } = this;

        if (this.leaveObj.id == "") {
          Utils.presentToastWarning("请选择请假申请");
          return;
        }
        //填写了填写销假日期的情况下（非取消休假），需要做校验 addby hansl
        let charFlag = false;
        if (this.cancelType == 0) {
          if (startDate == "" || startDate == "点击选择时间") {
            Utils.presentToastWarning("请选择实际开始时间");
            return;
          }
          if (endDate == "" || startDate == "点击选择时间") {
            Utils.presentToastWarning("请选择实际结束时间");
            return;
          }
          let date1 = new Date(
            moment(startDate).format("yyyy-MM-DD") +
              " " +
              (startNoon == "AM" ? "09:00:00" : "15:00:00")
          );
          let date2 = new Date(
            moment(endDate).format("yyyy-MM-DD") +
              " " +
              (endNoon == "AM" ? "09:00:00" : "15:00:00")
          );
          if (date1.getTime() > date2.getTime()) {
            charFlag = true;
          }
          if (charFlag) {
            Utils.presentToastWarning("实际开始时间不能晚于实际结束时间");
            return;
          }

          //是否需要附件：0不需要，1：请假时需要；2销假时需要；3:请假或销假时至少有一个
          if (
            (this.leaveObj.attachmentRequired == 2 &&
              attachments.length == 0) ||
            (this.leaveObj.attachmentRequired == 3 &&
              this.leaveObj.attachments.length == 0 &&
              attachments.length == 0)
          ) {
            Utils.presentToastWarning("请上传附件");
            return;
          }
          //产前检查、流产：请假或销假时必须有一个附件
          if (
            this.leaveObj.vacationCode == this.cjCode &&
            (this.leaveObj.extType == "产前检查" ||
              this.leaveObj.extType == "流产") &&
            this.leaveObj.attachments.length == 0 &&
            attachments.length == 0
          ) {
            Utils.presentToastWarning("请上传附件");
            return;
          }
        }
        if (reason != null && reason != "" && Utils.isInCludeEmoji(reason)) {
          charFlag = true;
        }
        if (charFlag) {
          Utils.presentToastWarning("说明不可以包含表情");
          return;
        }

        let params = {
          id: id,
          vacationType: vacationType,
          vacationTypeId: vacationTypeId,
          extType: extType,
          leaveId: this.leaveObj.id,
          startDate: startDate,
          startNoon: startNoon,
          endDate: endDate,
          endNoon: endNoon,
          days: days,
          reason: reason,
          attachments: attachments,
          procId: procId,
          taskId: taskId,
          cancelLeave: this.cancelType == 1,
        };
        let paramNextStepUser = { VacationTypeId: this.vacationTypeId };
        //获取下一步骤处理人
        this.leaveService
          .getNextStepUsers(paramNextStepUser)
          .then((result: any) => {
            let that = this;
            if (result && result.length > 0) {
              this.firstLeaders = result;
              this.saveLeave(
                params,
                function (loadingCall: any) {
                  that.presentModal();
                  loadingCall();
                  setTimeout(() => {
                    that.isClick = true;
                  }, 1000);
                },
                () => {}
              );
            } else {
              this.cancleLeaveService
                .beforeSubmit(params)
                .then((result: any) => {
                  if (result) {
                    Utils.confirmDialog1(
                      "确认",
                      "请确认是否提交销假申请",
                      function () {
                        that.isClick = false;
                        that.saveLeaveHandler(
                          params,
                          () => {},
                          () => {
                            setTimeout(() => {
                              that.isClick = true;
                            }, 1000);
                          }
                        );
                      },
                      function () {
                        that.isClick = true;
                      }
                    );
                  } else {
                    that.isClick = true;
                    Utils.presentToastWarning(result.error);
                  }
                })
                .catch((ex: any) => {
                  that.isClick = true;
                  if (ex && ex.data && ex.data.error) {
                    Utils.presentToastWarning(ex.data.error);
                  }
                });
            }
          })
          .catch(() => {});
      }
    },
    saveLeave(params: any, callback: any, loadingcall: any) {
      this.cancleLeaveService
        .submit(params)
        .then((result: any) => {
          this.isClick = true;
          if (result) {
            this.id = result.businessId;
            this.businessId = result.businessId;
            this.taskId = result.taskId;
            this.procId = result.procId;
            callback(loadingcall);
          } else {
            loadingcall();
            this.isClick = true;
            Utils.presentToastWarning("提交失败");
          }
        })
        .catch(() => {
          loadingcall();
          this.isClick = true;
        });
    },
    saveLeaveHandler(params: any, ok: any, fail: any) {
      let that = this;
      this.saveLeave(
        params,
        function (ok: any) {
          //发送
          let paramSend = {
            procId: that.procId,
            taskId: that.taskId,
            listReciveVar: [],
            option: "",
          };
          that.sendWorkflow(paramSend, () => {
            ok();
          });
        },
        function () {
          fail();
        }
      );
    },
    /**
     * 选下一步处理人
     */
    async presentModal() {
      const modal = await modalController.create({
        component: SendPage,
        cssClass: "notice-modal-class",
        mode: "ios",
        componentProps: {
          data: {
            businessId: this.businessId,
            taskId: this.taskId,
            procId: this.procId,
            receiveParameters: "assigneeList",
            userList: this.firstLeaders,
          },
        },
      });
      await modal.present();
      // this.sendModal = modal;
      const { data } = await modal.onDidDismiss();
      //返参
      if (data && data.save) {
        let that = this;
        setTimeout(() => {
          that.router.go(-1);
        }, 500);
      }
    },
    sendWorkflow(paramSend: any, callback: any) {
      if (this.userId) {
        let userIds = new Array();
        userIds.push(this.userId);
        paramSend.listReciveVar = [
          {
            key: "assigneeList",
            value: userIds,
          },
        ];
      }
      this.workflowService
        .send(paramSend)
        .then((resultSend: any) => {
          if (resultSend) {
            if (resultSend.result) {
              callback();
              Utils.presentToastSuccess("提交成功");
              let that = this;
              setTimeout(() => {
                that.router.go(-1);
              }, 500);
            } else {
              callback();
            }
          }
        })
        .catch(() => {
          callback();
          this.isClick = true;
        });
    },
    deleteHandle() {
      let that = this;
      Utils.confirmDialog("确认", "确认删除该申请？", function () {
        let params = { id: that.id };
        that.cancleLeaveService
          .delete(params)
          .then((result: any) => {
            that.isClick = true;
            Utils.presentToastWarning("删除成功");
            setTimeout(() => {
              that.router.go(-1);
            }, 500);
          })
          .catch(() => {
            that.isClick = true;
          });
      });
    },
  },
});
