import { CancleLeaveApi } from '@/api/attendance/cancleleave/CancelLeaveApi';

export class CancleLeaveService {
    private api = new CancleLeaveApi();

    public submit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.submit(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public beforeSubmit(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.beforeSubmit(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getInfo(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getInfo(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public delete(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.delete(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
    public getList(params: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getList(params).then((res: any) => {
                resolve(res.data);
            }).catch((err: any) => {
                reject(err);
            });
        });
    }
}